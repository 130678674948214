import { Transition } from "@headlessui/react";

import { clsx } from "../modules/Utilkit/Utilkit";
import Auth, { useAuth } from "./components/Auth";
import Notify from "./components/Notify";
import ContextMenu from "./components/ContextMenu";
import Navbar, { useNavigator } from "./components/Navigator";
import Dashboard from "./apps/dashboard";
import TacticalPlanning from "./apps/tactical-planning";
import FloatPanel from "./components/FloatPanel";

import TacticalPlanningIcon from "../assets/images/apps/tactical-planning.png";
import DashboardIcon from "../assets/images/apps/dashboard.png";
import CalendarManagementIcon from "../assets/images/apps/data-management.png";
import Devices from "./apps/devices";
import CalendarManagement from "./apps/calendar-management";

export default function App() {
	const { authStatus, handleSignOut } = useAuth();
	const { go } = useNavigator();

	return (
		<div className="relative w-full h-full">
			<Notify />
			<ContextMenu />
			<FloatPanel />

			{ window.location.pathname === '/samsungtv-dashboard' ? (
				authStatus === 'success' && (
					<div className="z-[999999] absolute top-0 left-0 w-full bg-white flex flex-col">
						<button className="text-red-600 underline self-end pt-1 text-lg w-fit mx-5" onClick={handleSignOut}>Esci</button>
						<Dashboard />
					</div>
				)
			) : (
			<Transition
				show={ authStatus === 'success' }
				as="div"
				className={ "relative w-full h-full" }
			>
				<div
					className={ clsx(
						"fixed top-0 left-0 z-40 flex justify-start items-center px-1 w-full duration-150",
						"bg-white border-b border-red-600 h-[calc(env(titlebar-area-height,31px)+2px)]"
					) }
				>
					<Navbar
						nav={ [
							{ id: 'dashboard', name: 'Dashboard', description: 'Statistiche sintetiche', image: DashboardIcon },
							{ id: 'tactical-planning', name: 'Tactical Planning', description: 'Pianifica le risorse', image: TacticalPlanningIcon },
							{ id: 'calendar-management', name: 'Calendar Management', description: 'Gestione del calendario', image: CalendarManagementIcon }
						] }
						usernav={ [
							{ id: 'devices', name: 'Dispositivi', icon: { type: 'rr', name: 'devices' }, onClick: () => go('devices') },
							{ id: 'logout', name: 'Log Out', className: 'text-red-600', icon: { type: 'rr', name: 'exit' } }
						] }
					/>
				</div>

				<div className="absolute w-full bg-white top-[33px] left-0 h-[calc(100%-33px)] overflow-y-auto z-30">
					<PageTransition page="dashboard">
						<Dashboard />
					</PageTransition>

					<PageTransition page="tactical-planning">
						<TacticalPlanning />
					</PageTransition>

					<PageTransition page="devices">
						<Devices />
					</PageTransition>

					<PageTransition page="calendar-management">
						<CalendarManagement />
					</PageTransition>
				</div>
			</Transition>
			) }

			<Transition
				show={ authStatus === 'sign-in-required' }
				as="div"
				className={ "w-full h-full z-40" }
				enter="ease-out duration-300"
				enterFrom="opacity-0 absolute -top-1/4 z-10"
				enterTo="opacity-100 absolute top-0 z-10"
				leave="ease-in duration-300"
				leaveFrom="opacity-100 absolute top-0 z-10"
				leaveTo="opacity-0 absolute -top-1/4 z-10"
			>
				<Auth />
			</Transition>
		</div>
	);
}

const PageTransition = ({ page, children }) => {
	const { active } = useNavigator();

	return (
		<Transition
			as="div"
			show={ active === page }
			className={ clsx("w-full h-full", active !== page && "absolute") }
			enter="duration-300"
			enterFrom="opacity-0"
			enterTo=" opacity-100"
			leave="duration-75"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			{ children }
		</Transition>
	);
};